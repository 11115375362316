import { useNavigate } from "react-router-dom";
import InputGenerator from "../../../components/Login/InputGenerator";
import "../SignInPage.css";


function SignUpPage1({ handleChange, email, password, confirmPassword }) {
    const navigate = useNavigate();


    function validate() {
        if (!email || !email.includes("@")) {
            return alert("Please enter a valid email.");
        } else if (!password || !confirmPassword) {
            return alert("Please enter matching passwords.");
        } else if (password.length < 6) {
            return alert("Password must be longer than 6 characters.");
        } else if (password !== confirmPassword) {
            return alert("Passwords do not match!");
        } else {
            navigate("/sign-up/sign-in2");
        }
    }

    return (
        <>
            <InputGenerator
                inputs={[
                    {
                        label: "Email",
                        type: "email",
                        value: email,
                    },
                    {
                        label: "Password",
                        type: "password",
                        value: password,
                    },
                    {
                        label: "Confirm",
                        type: "password",
                        value: confirmPassword,
                    },
                ]}
                handleChange={handleChange}
            />

            <button className="sign-in-button" onClick={validate}>
                Next
            </button>
        </>
    );
}

export default SignUpPage1;
