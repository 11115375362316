import { useState } from "react"
import { useRef } from "react"
import LoadingSpinner from "../../../components/Loading/LoadingSpinner"
import "./EmbeddedPage.css"



/**
 * 
 * @param {String} sourceType winterNewsLetter, springNewsLetter, summerNewsLetter, fallNewsLetter, or gallery
 * @returns 
 */
export default function EmbeddedPage({sourceType}) {
  const [isLoading, setIsLoading] = useState(true)
  const iframeEl = useRef(null)
  const url = {
    winterNewsLetter: "https://yardtofork.hubspotpagebuilder.com/yard-to-fork-winter-newsletter-2022",
    springNewsLetter: "https://www.yardtofork.hubspotpagebuilder.com/yard-to-fork-spring-newsletter-2022",
    summerNewsLetter: "https://yardtofork.hubspotpagebuilder.com/yard-to-fork-summer-newsletter-2022",
    fallNewsLetter: "https://yardtofork.hubspotpagebuilder.com/yard-to-fork-fall-newsletter-2022",
    gallery: "https://www.yardtofork.com/portfolio",
    schedule: "https://yardtoforkraisingedibleyards.as.me/"
  }


  function handleLoad(){
    setIsLoading(false)
  }

  return (
    <div className="info-iframe-container">
      {isLoading && <LoadingSpinner />}
    <iframe className={sourceType === "gallery" ? "info-iframe-hide-top" : "info-iframe"} title="newsLetter" allowModal="false" src={url[sourceType]} ref={iframeEl} onLoad={handleLoad} loading="eager" height={200}>
 

    </iframe>
  </div>
  
  )
}
