import { Accordion } from "react-bootstrap"
import { RiDeleteBin2Line } from "react-icons/ri"
import "./Selection.css"
import { useState } from "react"
import WarningModal from "../Modals/WarningModal"
import { deleteUser } from "../../firestore/firestore"
import LoadingSpinner from "../Loading/LoadingSpinner"

export default function EmployeeAccordion({ eventKey, id, employee, refreshCallback, children }) {
  const [showModal, setShowModal] = useState(false)
  const [warningModalInfo, setWarningModalInfo] = useState([])
  const [confirmDelete, setConfirmDelete] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  function handleDelete() {
    setWarningModalInfo([/*Title*/ ` ${employee.fullName}`, /*Description*/ `If you delete ${employee.fullName} you will not be able to recover this employee's information.`, /*Confirm Text*/ "Delete Employee"])
    setShowModal(true)
    setConfirmDelete(() => confirmDeleteEmployee)
  }

  function confirmDeleteEmployee() {
    setShowModal(false)
    setIsLoading(true)
        deleteUser(employee.id).then((results) => {
          setIsLoading(false)
          if(results === "success") {
            alert("Employee deleted successfully.")
            refreshCallback()
          }
        })
  }

  

  return (
    <>
    <Accordion.Item eventKey={eventKey} className="custom-accordion-item" id={id} key={id + employee}>
      <Accordion.Header className="custom-accordion-header">{employee.fullName}</Accordion.Header>
      <Accordion.Body className="relative">
        {children}
        <span className="medium-font delete-employee-icon" onClick={() => handleDelete()}>
          <RiDeleteBin2Line className="text-danger" />
        </span>
      </Accordion.Body>
      <Accordion.Body className></Accordion.Body>
    </Accordion.Item>
       <WarningModal show={showModal} close={() => setShowModal(false)} confirmDelete={confirmDelete} modalInfo={warningModalInfo} />
        {isLoading && <LoadingSpinner />}
    </>
  )
}
