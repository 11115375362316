import React, { useState } from "react";
import { FormControl, FormGroup } from "react-bootstrap";
import { FaRegEye } from "react-icons/fa";
import { FaRegEyeSlash } from "react-icons/fa";

/**
 *
 *InputGroup function
 * @param {Array} inputs: Array of objects. Each object represents an input with a label, type, value, and handler i.e., [{label:"Name", type:text, value:name, handler:handleName}]
 * @param {function} submit: submit form handler.
 */
export default function InputGenerator({
    inputs, //Array of objects that include labels and inputs.
    handleChange,
}) {
const [showPassword, setShowPassword] = useState(false);

const togglePasswordVisibility = (index) => {
  setShowPassword(!showPassword);
};

    return (
      <>
      <FormGroup>
          {inputs.map((input, i) => {
              return (
                  <React.Fragment key={input.label + i}>
                      <label htmlFor={input.label + i}>
                          {input.label}
                      </label>
                      <div style={{ position: "relative" }}>
                          <FormControl
                              type={
                                  input.type === "password" && showPassword
                                      ? "text"
                                      : input.type
                              }
                              name={input.label.toLowerCase()}
                              id={input.label + i}
                              value={input.value}
                              required
                              onChange={handleChange}
                              pattern={input.pattern}
                              placeholder={input.placeholder}
                          />
                          {input.type === "password" && (
                              <span
                                  onClick={() => togglePasswordVisibility(i)}
                                  style={{
                                      position: "absolute",
                                      right: 10,
                                      top: "50%",
                                      transform: "translateY(-50%)",
                                      cursor: "pointer",
                                  }}
                              >
                                  {showPassword ? <FaRegEye /> : <FaRegEyeSlash />}
                              </span>
                          )}
                      </div>
                  </React.Fragment>
              );
          })}
      </FormGroup>
  </>
    );
}
