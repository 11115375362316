import React, { useEffect, useState } from "react"
import { Route, Routes, useNavigate } from "react-router-dom"
import Footer from "../../components/Footer/Footer"
import Navigation from "../../components/Nav/Navigation"
import Preferences from "./Preferences/Preferences"
import Home from "./Home/Home"
import AdminHome from "./Admin/adminHome/AdminHome"
import EmployeeHome from "./Employee/employeeHome/EmployeeHome"
import Customers from "./Admin/customers/Customers"
import LoadingSpinner from "../../components/Loading/LoadingSpinner"
import "./Main.css"
import EditPlants from "./Admin/editPlants/EditPlants"
import EditPlant from "./Admin/editPlants/EditPlant"
import { selectActiveUser } from "../../features/user/userSlice"
import { useSelector } from "react-redux"
import AddPlant from "./Admin/editPlants/AddPlant"
import AdminSettings from "./Admin/adminSettings/AdminSettings"
import EmployeeSettings from "./Employee/employeeSettings/EmployeeSettings"
import MessageYTF from "./MessageYTF/MessageYTF"
import ContactPage from "./Contact/ContactPage"
import EmbeddedPage from "./Embedded/EmbeddedPage"
import { getPWADisplayMode } from "../../util/helper"
import InitialIntroPage from "./Initial/InitialPage"
import Employees from "./Admin/employees/employees"

export default function Main() {
  const [user, setUser] = useState("")
  const navigate = useNavigate()
  const activeUser = useSelector(selectActiveUser)

  useEffect(() => {
    setUser(activeUser)
  }, [activeUser])

  useEffect(() => {
    const displayMode = getPWADisplayMode()

    if (displayMode === "browser" && window.innerWidth < 768) {
      navigate("/initialIntro")
    }
  }, [navigate])

  if (user?.role === "customer") {
    return (
      <div className="main-container">
        <Navigation user={user} />
        <div className="d-flex justify-content-center w-100 h-100">
          <Routes>
            
            <Route path="/initialIntro" element={<InitialIntroPage user={user} />}></Route>
            <Route path="/" element={<Home user={user} />}></Route>
            <Route path="/preferences" element={<Preferences user={user} />}></Route>
            <Route path="/messageYTF/:userId" element={<MessageYTF />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/info" element={<EmbeddedPage sourceType="schedule"/>} />
            <Route path="/gallery" element={<EmbeddedPage sourceType="gallery"/>} />
          </Routes>
        </div>
        <Footer />
      </div>
    )
  } else if (user?.role === "admin") {
    return (
      <div className="main-container">
        <Navigation user={user} />
        <div className="d-flex justify-content-center w-100 h-100">
          <Routes>
          <Route path="/initialIntro" element={<InitialIntroPage user={user} />}></Route>
            <Route path="/" element={<AdminHome user={user} />} />
            <Route path="/customers" element={<Customers />} />
            <Route path="/employees" element={<Employees />} />
            <Route path="/edit-plants" element={<EditPlants />} />
            <Route path="/edit-plant/:name" element={<EditPlant />} />
            <Route path="/add-plant" element={<AddPlant />} />
            <Route path="/admin-settings" element={<AdminSettings />} />
            
          </Routes>
        </div>
        <Footer />
      </div>
    )
  } else if(user?.role === "employee") {
    return (
      <div className="main-container">
        <Navigation user={user} />
        <div className="d-flex justify-content-center w-100 h-100">
          <Routes>
          <Route path="/initialIntro" element={<InitialIntroPage user={user} />}></Route>
            <Route path="/" element={<EmployeeHome user={user} />} />
            <Route path="/customers" element={<Customers />} />
            <Route path="/edit-plants" element={<EditPlants />} />
            <Route path="/edit-plant/:name" element={<EditPlant />} />
            <Route path="/add-plant" element={<AddPlant />} />
            <Route path="/employee-settings" element={<EmployeeSettings />} />

          </Routes>
        </div>
        <Footer />
      </div>
    )
  }
  else {
    return <LoadingSpinner />
  }
}
