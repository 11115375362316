import HomeButton from "../Buttons/HomeButton"
import './HomeButtonsContainer.css'

/**
 * 
 * @param {user} object example: "/info","/gallery"...
 * @returns JSX
 */
export default function HomeButtonsContainer({user}) {

  let firstButton = ['/', ""]
  let secondButton = ['/', ""]
  let thirdButton = ['/', ""]
  let fourthButton = ['/', ""]

  switch (user.role) {
    case 'admin':
      firstButton = ["/customers","Customers"]
      secondButton =  ["/edit-plants","Plants"]
      thirdButton = ["/","Messages"]
      fourthButton =  ["/employees","Employees"]
      break;
    case 'employee':
      firstButton = ["/customers","Customers"]
      secondButton = ["/edit-plants","Plants"]
      thirdButton = ["/","To Do"]
      fourthButton = ["/","Notes"]
      break;
    case 'customer':
      firstButton = ['/preferences','My Plants']
      secondButton = ['/info', 'Schedule Service']
      thirdButton = ['/gallery', 'Gallery']
      fourthButton = ['/contact', 'Contact']
      break;
  
    default:
      break;
  }

  return (
    <div className="home-buttons-container">
      <div className="d-flex justify-content-center px-3 home-buttons-row">
        <HomeButton to={firstButton[0]} buttonName={firstButton[1]} />
        <HomeButton to={secondButton[0]} buttonName={secondButton[1]} />
      </div>
      <div className="d-flex justify-content-center flex-wrap px-3 pb-2 mb-1 home-buttons-row">
        <HomeButton to={thirdButton[0]} buttonName={thirdButton[1]} />
        <HomeButton to={fourthButton[0]} buttonName={fourthButton[1]} />
      </div>
    </div>
  )
}


