import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore/lite";
import { getFunctions } from "firebase/functions"; // Import getFunctions

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_PUBLIC_FIREBASE_API_KEY,
  authDomain: "yard-to-fork-2585e.firebaseapp.com",
  projectId: process.env.REACT_APP_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: "yard-to-fork-2585e.appspot.com",
  messagingSenderId: "774685207583",
  appId: "1:774685207583:web:099f91f278798cfea6392a",
  measurementId: "G-E0HQVHBP0L",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions(app); // Initialize functions