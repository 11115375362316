import { collection, getDocs, setDoc, updateDoc, doc, getDoc, query, where, arrayUnion, deleteDoc } from "firebase/firestore/lite"
import { db, functions } from "../Config/firebaseConfig"
import { httpsCallable } from "firebase/functions"

const usersCollectionRef = collection(db, "users")
const plantsCollectionRef = collection(db, "plants")
const errorLogsCollectionRef = collection(db, "errorLogs")

//----------USER----------
export const createUser = async (userData) => {
  try {
    const document = await doc(usersCollectionRef, userData.id)
    const created = await setDoc(document, userData, { merge: true })
    if (created) {
      return true
    }
  } catch (err) {
    createErrorLog(err)
  }
}

export const getUserInfo = async (userId) => {
  try {
    const document = doc(usersCollectionRef, userId)
    const userData = await getDoc(document)

    return userData.data()
  } catch (err) {
    createErrorLog(err)
  }
}

export const updateAllUserInfo = async (userInfo) => {
  try {
    const document = doc(usersCollectionRef, userInfo.id)
    await updateDoc(document, userInfo)
  } catch (err) {
    createErrorLog(err)
  }
}

export const updateUserPlants = async (userId, plantData) => {
  try {
    const document = doc(usersCollectionRef, userId)
    await updateDoc(document, {
      plants: [...plantData],
    })
  } catch (err) {
    createErrorLog(err)
  }
}

export const updateSpecialtyRequests = async (userId, specialtyRequests) => {
  try {
    const document = await doc(usersCollectionRef, userId)
    const createdOrUpdated = await setDoc(document, { specialtyRequests: specialtyRequests }, { merge: true })
    if (createdOrUpdated) {
      return true
    }
  } catch (err) {
    createErrorLog(err)
  }
}

export const editDbPrimaryNote = async (userId, primaryNote) => {
  try {
    const document = doc(usersCollectionRef, userId)
    return await updateDoc(document, {
      primaryNote,
    })
  } catch (err) {
    createErrorLog(err)
  }
}

export const createNote = async (userId, newNote) => {
  try {
    const document = doc(usersCollectionRef, userId)
    const user = await getDoc(document)
    const notes = user.data().notes
    const notesLimit = 99
    if (notes?.length > notesLimit) {
      const trimmedNotes = notes.slice(notes.length - notesLimit)
      trimmedNotes.push(newNote)
      await updateDoc(document, {
        notes: trimmedNotes,
      })
    } else {
      return await updateDoc(document, {
        notes: arrayUnion(newNote),
      })
    }
  } catch (err) {
    createErrorLog(err)
  }
}

export const editNote = async (userId, editedNote) => {
  try {
    const document = doc(usersCollectionRef, userId)
    const user = await getDoc(document)
    const notes = user.data().notes
    const editedNotes = notes.map((note) => {
      if (note.id === editedNote.id) {
        return editedNote
      } else {
        return note
      }
    })
    await updateDoc(document, {
      notes: editedNotes,
    })
  } catch (err) {
    createErrorLog(err)
    alert("An error occurred while trying to edit the note.")
  }
}

export const deleteNote = async (userId, noteId) => {
  // get notes array from user then filter out the note with the matching id
  try {
    const document = doc(usersCollectionRef, userId)
    const user = await getDoc(document)
    const notes = user.data().notes
    const filteredNotes = notes.filter((note) => note.id !== noteId)
    await updateDoc(document, {
      notes: filteredNotes,
    })
  } catch (err) {
    createErrorLog(err)
  }
}

export const updateMessages = async (userId, message) => {
  try {
    const document = doc(usersCollectionRef, userId)
    const timeStamp = Date.now()
    await updateDoc(document, {
      messages: arrayUnion({ message, userId, read: false, createdAt: timeStamp }),
    })
  } catch (err) {
    createErrorLog(err)
  }
}

//----------PLANTS----------
export const getPlantsInfo = async () => {
  try {
    const document = doc(plantsCollectionRef, "plants")
    const plantData = await getDoc(document)
    return plantData.data()
  } catch (err) {
    console.log(err.code)
    if (err.code === "permission-denied") {
      alert("You do not have permission get plants info.")
      createErrorLog(err)
    } else {
      createErrorLog(err)
    }
  }
}
/** 
@updatePlantsInfo Updates Firestore plants info.
@param plantsInfo Array of plant objects. Plant objects will have the following properties: name, size, description, imgUrl (optional), link (optional), plantingMonth[](optional).
*/
export const updatePlantsInfo = async (plants) => {
  try {
    if (plants) {
      const document = doc(plantsCollectionRef, "plants")
      const response = await setDoc(document, { plants }, { merge: true })
      return response
    }
  } catch (err) {
    if (err.code === "permission-denied") {
      alert("You do not have permission to modify plants")
      createErrorLog(err)
    } else {
      createErrorLog(err)
    }
  }
}

/**
@updateCategories Updates categories in editing mode to db
@param categories Array of categories
*/
export const updateCategories = async (categories) => {
  try {
    if (categories) {
      const document = await doc(plantsCollectionRef, "plants")
      await setDoc(document, { categories }, { merge: true })
    }
  } catch (err) {
    if (err.code === "permission-denied") {
      alert("You do not have permission to modify categories.")
      createErrorLog(err)
    } else {
      createErrorLog(err)
    }
  }
}

//----------ADMIN / EMPLOYEE----------
export const getAllCustomerInfo = async () => {
  try {
    const q = query(usersCollectionRef, where("role", "==", "customer"))
    const snapshot = await getDocs(q)
    const customerData = []
    snapshot.forEach((doc) => customerData.push(doc.data()))
    return customerData.sort((a, b) => a.lastName - b.lastName)
  } catch (err) {
    createErrorLog(err)
  }
}

//----------ADMIN ----------
export const getAllEmployeeInfo = async () => {
  try {
    const q = query(usersCollectionRef, where("role", "==", "employee"))
    const snapshot = await getDocs(q)
    const customerData = []
    snapshot.forEach((doc) => customerData.push(doc.data()))
    return customerData.sort((a, b) => a.lastName - b.lastName)
  } catch (err) {
    createErrorLog(err)
  }
}




export const deleteUser = async (userId) => {
  try {
    // const testFunction = httpsCallable(functions, "testFunction");
    // testFunction({userId}).then((result) => console.log(result.data));

    const document = doc(usersCollectionRef, userId)
    if(document){
      await deleteDoc(document)
    }
    const deleteUserFunction = httpsCallable(functions, "deleteUser")
    const result = await deleteUserFunction({ userId })
    if(result.data.message) {
      return "success"
    } else {
      throw new Error("Failed to delete user.")
    }
    // Optionally, delete the user document from Firestore
  } catch (err) {
    console.error("Client error:", err)
    alert("An error occurred while trying to delete the user.")
    createErrorLog(err)
  }
}

// export const deleteUser = async (userId) => {
//   try {
//     const deleteUserFunction = httpsCallable(functions, "deleteUser");
//     const result = await deleteUserFunction({ userId });
//     console.log(result.data.message);

//     // Optionally, delete the user document from Firestore
//     const document = doc(usersCollectionRef, userId);
//     await deleteDoc(document);
//   } catch (err) {
//     createErrorLog(err);
//   }
// };

// ----------ERROR LOGS----------
export const createErrorLog = async (error) => {
  console.log(error)
  const message = error.message
  const code = error.code
  const createdAt = Date.now()
  const dateAndTime = new Date(createdAt).toLocaleString()
  const errorLog = { dateAndTime, message, code, createdAt }

  try {
    const document = doc(errorLogsCollectionRef, "errorLogs")
    const created = await updateDoc(document, { errorLogs: arrayUnion(errorLog) })
    if (created) {
      return true
    }
  } catch (err) {
    alert(err.message)
  }
}
